import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

Vue.config.errorHandler = function (err, vm, info) {
  console.error('Vue error handler:', err);
  // 自定义的错误处理逻辑
};

window.addEventListener('unhandledrejection', function (event) {
  // console.error('Unhandled promise rejection:', event.reason);
  event.preventDefault();
});

window.addEventListener('error', function (event) {
  console.error('Global error caught:', event.message);
  event.preventDefault();
});

export const EventBus = new Vue()
const bus = new Vue()
Vue.prototype.$bus = bus
Vue.config.productionTip = false
Vue.use(Element)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
