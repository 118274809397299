import request from './request'

// 文件上传
export function fileUpload(data) {
  return request({
    url: '/file/upload',
    method: 'post',
    data
  })
}

// 评价
export function bEvaluateUpdate(data) {
  return request({
    url: '/bEvaluate/update',
    method: 'post',
    data
  })
}

// 评价
export function bConversationEnd(params) {
  return request({
    url: '/bConversation/end',
    method: 'get',
    params
  })
}

// 样式查询
export function bPublishGetInfo(params) {
  return request({
    url: '/bPublish/getInfo',
    method: 'get',
    params
  })
}

// 编辑客户信息
export function bClientInfoUpdate(data) {
  return request({
    url: '/bClientInfo/update',
    method: 'post',
    data
  })
}


