<template>
  <div id="app">
    <audio id="music" ref="msgTipAudio" controls="controls" hidden>
      <source src="./assets/promptTone.mp3" type="audio/mpeg">
    </audio>
    <router-view/>
  </div>
</template>

<script>
import { EventBus } from '@/main'
import Push from 'push.js'
import {
  bConversationEnd,
} from '@/api/api'
export default {
  data() {
    return {
      img: require('@/icons/img/logoPush.png'),
    }
  },
  mounted() {
 

    this.setupUnloadListeners();
    // 监听事件，并在收到事件时执行播放音频的方法
    EventBus.$on('playAudio', () => {
    // 获取音频元素
      var audio = document.getElementById('music')
      this.pushMessage('您有一条新的会话消息提醒，请立即处理')

      // 播放音频
      audio.play().catch(err => {
        console.log(err)
      })
    })
  },
  beforeDestroy() {
  },
  methods: {
  
    setupUnloadListeners() {
      let beforeUnloadTime = 0;
      window.onbeforeunload = (event) => {
        beforeUnloadTime = new Date().getTime()
      };

      window.onunload = (event) => {
        const gapTime = new Date().getTime() - beforeUnloadTime;
        // 当两次事件发生时间戳小于4时，才触发关闭浏览器要执行的事件  
        
        event.preventDefault();
        if (gapTime <= 4) {

          bConversationEnd({
            conversationId: localStorage.getItem('conversationId')
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('关闭成功')
              // setTimeout(() => {
              //   window.close();
              //   window.location.href = "about:blank";
              // }, 3000);
              this.wsService.close()
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          console.log("这是一个刷新页面的操作");
        }
      };
    },
    pushMessage(message) {
      Push.Permission.request()
      Push.create('', {
        body: message,
        //  requireInteraction: true,
         icon: this.img,
        timeout: 3000
      })
    },
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0 !important;
  padding: 0;
}


</style>
