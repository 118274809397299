import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //121050cd-1a91-4609-9dba-bfcddb0fc594
    token: '',
    conversationId: null
  },
  getters: {
  },
  mutations: {
    SETID(state, conversationId) {
      state.conversationId = conversationId
      console.log(conversationId);
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      // 配置
      storage: window.localStorage, // 默认是localStorage
      // paths: ['user', 'cart'], // 你想持久化的状态
    }),
  ],
})
