import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
// 创建axios实例
const service = axios.create({
  // baseURL: process.env.NODE_ENV==='development'?'http://192.168.31.181:8091/szby-ccm':'http://120.24.97.206:8091/szby-ccm', // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    if (config.url === '/file/upload') {
      config.timeout = 100000
    }
    // 在发送请求之前做点什么
    // if (store.getters.token) {
    //   // 让每个请求携带令牌
    //   //['X-Token']是一个自定义头密钥
    //   //每次请求携带请求头

    config.headers['Authorization'] = store.state.token
    // }
    return config
  },
  error => {
    // 处理请求错误
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(

  response => {
    const res = response.data
    // 如果自定义代码不是200，则判断为错误。
    if (res.code !== 200 && response.status !== 200) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })


      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    if (!error.response) {
      // 网络错误
      Message({
        message: '网络超时,请检查您的网络连接',
        type: 'error',
        duration: 5 * 1000
      });
    } else {
      // 处理其他错误响应
      Message({
        message: error.response.data.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      });
    }
    return Promise.reject(error.msg)
  }
)

export default service
